<template>
  <div>
    <div class="d-flex mb-2">
      <span class="h4 text-secondary mr-auto my-auto">Liste des acteurs </span>

      <div class="d-flex justify-content-end">
        <customAddButton
          :disabled="disabled || !(isAdmin || isSuper) ? true : false"
          text="Créer un acteur"
          @click="craeteinput"
        ></customAddButton>
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <!-- {{ ENTITIE.organisations }} -->
    <b-table
      responsive
      :items="tableItems || []"
      :fields="tableFields"
      bordered
      show-empty
      striped
      :per-page="perPage"
      :current-page="currentPage"
      :busy="loading"
    >
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <template v-slot:cell(isActif)="data">
        <span
          v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
        >
          <b-badge v-if="data.value" variant="success">Actif</b-badge>
          <b-badge v-else variant="danger">Inactif</b-badge></span
        >
        <v-select
          style="width: 150px !important"
          placeholder="Statut"
          disabled
          v-else
          v-model="newActeur.Statut"
          :options="['Actif', 'Inactif']"
        />
      </template>
      <template v-slot:cell(actor)="data">
        <TableResponsable
          v-if="!data.item.hasOwnProperty('editMode')"
          :responsable="data.item"
        ></TableResponsable>
        <div v-else>
          <v-select
            label="text"
            :reduce="(user) => user.value"
            v-model="newActeur.actor"
            appendToBody
            placeholder="Acteur"
            @input="change"
            :disabled="data.item.id ? true : false"
            :options="responsables(data.item.id)"
            style="padding-right: 0 !important"
            :class="{
              'is-invalid form-control p-0':
                $v.newActeur.actor.$error && $v.newActeur.actor.$dirty,
            }"
            type="text"
          >
            >
          </v-select>

          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.newActeur.actor.$dirty"
          >
            {{ !$v.newActeur.actor.required ? "Champ obligatoire" : "" }}
          </span>
        </div>
      </template>

      <template v-slot:cell(email)="data">
        <span
          v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
        >
          {{ data.value ? data.value : "-" }}
        </span>
        <b-form-input
          style="width: 150px !important"
          disabled
          v-else
          v-model="newActeur.Email"
          placeholder="Email"
          type="text"
        />
      </template>

      <template v-slot:cell(type)="data">
        <span
          v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
        >
          {{ data.value ? data.value : "-" }}
        </span>
        <v-select
          style="width: 150px !important"
          disabled
          placeholder="Type"
          v-else
          appendToBody
          v-model="newActeur.type"
          :options="['interne', 'Externe']"
        />
      </template>

      <template v-slot:cell(organisation)="data">
        <!-- {{ newActeur.Organisation }} -->
        <span
          v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
        >
          {{ data.value ? data.value.libelle : "-" }}
        </span>
        <div v-else>
          <v-select
            style="padding-right: 0 !important"
            :class="{
              'is-invalid form-control p-0':
                $v.newActeur.Organisation.$error &&
                $v.newActeur.Organisation.$dirty,
            }"
            placeholder="Organisation de rattachement"
            appendToBody
            label="text"
            :reduce="(organisations) => organisations.value"
            :options="organisation"
            v-model="newActeur.Organisation"
          />
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.newActeur.Organisation.$dirty"
          >
            {{ !$v.newActeur.Organisation.required ? "Champ obligatoire" : "" }}
          </span>
        </div>
      </template>

      <template v-slot:cell(function)="data">
        <span
          v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
        >
          {{ data.value ? data.value : "-" }}
        </span>
        <v-select
          style="width: 150px !important"
          v-else
          disabled
          :options="fonction"
          v-model="newActeur.Fonction"
          placeholder="Fonction"
          type="text"
          appendToBody
        />
      </template>
      <template v-slot:cell(hiearchie)="data">
        <span
          v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
        >
          {{ data.value ? data.value : "-" }}
        </span>
        <v-select
          style="width: 150px !important"
          disabled
          placeholder="Niveau hiérarchique"
          v-model="newActeur.Niveau"
          v-else
          :options="hierachie"
          appendToBody
        />
      </template>

      <template v-slot:cell(actions)="data">
        <table-actions
          :actions="isAdmin || isSuper ? ['delete', 'edit'] : []"
          :editMode="data.item.hasOwnProperty('editMode')"
          @cancelItem="removeTeamInputs()"
          @deleteItem="deleteItem(data.item)"
          @editItem="editNewTeamInputs(data.item, data.item.id)"
          @confirmItem="addActeur(data.item)"
        />
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import TableResponsable from "@/components/custom/TableResponsable";
import Swal from "sweetalert2";
import customAddButton from "@/components/custom/CustomAddButton";

export default {
  components: { TableResponsable, TableActions, customAddButton },
  props: {
    disabled: Boolean,
  },
  validations: {
    newActeur: {
      actor: {
        required,
      },
      Organisation: {
        required,
      },
    },
  },
  data: () => ({
    loading: true,
    editedItem: null,
    tableItems: [],
    sortBy: "name",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    newActeur: {
      Nom: "",
      Prenom: "",
      Email: "",
      Organisation: null,
      Fonction: null,
      Niveau: "",
      Statut: false,
      type: "",
      actor: "",
    },
    tableFields: [
      { key: "actor", label: "Acteur", sortable: true },
      { key: "email", label: "Email", sortable: true },
      { key: "type", label: "Type", sortable: true },
      { key: "isActif", label: "Compte", sortable: true },
      {
        key: "organisation",
        label: "Organisation de rattachement",
        sortable: true,
      },
      { key: "function", label: "Fonction", sortable: true },
      {
        key: "hiearchie",
        label: "Niveau hiérarchique", 
        sortable: true,
      },
      {
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px !important", minWidth: "150px !important" },
        sortable: false,
      },
    ],
  }),
  created() {
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("users/fetchierachie");
    this.$store.dispatch("users/fetchfunction");
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store
      .dispatch("entity/fetchActeurs", this.$route.params.id)
      .then(() => {
        this.loading = false;
      });

    // this.$store
    //   .dispatch("entity/fetchEntitie", this.$route.params.id)
    //   .then(() => {
    //     this.loading = false;
    //   });
  },
  methods: {
    craeteinput() {
      if (this.isAdmin || this.isSuper) {
        window.console.log(this.tableItems);
        var data = this.tableItems.find(
          (contributer) => contributer.editMode == true
        );
        if (data == null) {
          this.currentPage = 1;
          this.tableItems.unshift({ editMode: true });
          this.newActeur.actor = "";
          this.newActeur.Email = "";
          this.newActeur.Statut = "";
          this.newActeur.Organisation = "";
          this.newActeur.Fonction = "";
          this.newActeur.Niveau = "";
          this.newActeur.type = "";
        }
      }
    },
    change() {
      if (!this.newActeur.actor) {
        this.newActeur.Email = "";
        this.newActeur.Statut = "";
        this.newActeur.Organisation = "";
        this.newActeur.Fonction = "";
        this.newActeur.Niveau = "";
        this.newActeur.type = "";
      } else {
        this.$store
          .dispatch("users/fetchUser", this.newActeur.actor)
          .then(() => {
            this.newActeur.Email = this.USER.email;
            this.newActeur.Statut =
              this.USER.isActif == true ? "Actif" : "Inactif";
            this.newActeur.Fonction = this.USER.function;
            this.newActeur.Niveau = this.USER.hiearchie;
            this.newActeur.type = this.USER.type;
            // this.newActeur.Organisation = this.USER.organisationRatachement
            //   ? this.USER.organisationRatachement.id
            //   : [];
          });
      }
    },
    editNewTeamInputs(data) {
      this.$store
        .dispatch("entity/fetchActeurs", this.$route.params.id)
        .then(() => {
          this.tableItems = this.tableItems.map((contributer) =>
            contributer.id === data.id
              ? { ...contributer, editMode: true, Modify: true }
              : contributer
          );
          this.newActeur.Email = data.email;
          this.newActeur.Statut = data.isActif == true ? "isActif" : "Inactif";
          this.newActeur.Fonction = data.function;
          this.newActeur.Niveau = data.hiearchie;
          this.newActeur.type = data.type;
          this.newActeur.Organisation = data.organisation
            ? data.organisation.id
            : null;
          this.newActeur.actor = data.id;
        });
    },
    deleteItem(item) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          var table = this.tableItems
            .filter((data) => data.id !== item.id)
            .map((data) => data["@id"]);
          var orga = {
            id: item.organisation.id,
            users: table,
          };
          this.$store
            .dispatch("organisation/updateOrganisation", orga)
            .then(() => {
              // Swal.fire("L'acteur est bien créé !", "", "success");
              Swal.fire("Supprimé!", "", "success");

              this.$store
                .dispatch("entity/fetchEntitie", this.$route.params.id)
                .then(() => {
                  this.$store
                    .dispatch("entity/fetchActeurs", this.$route.params.id)
                    .then(() => {
                      this.newActeur.Email = "";
                      this.newActeur.Statut = "";
                      this.newActeur.Organisation = "";
                      this.newActeur.Fonction = "";
                      this.newActeur.Niveau = "";
                      this.newActeur.type = "";
                      this.newActeur.actor = null;
                    });
                });
            });
        }
      });
    },
    addActeur() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        // if (item.id) {
        var table = this.tableItems.map((data) => data["@id"]);
        table.push(`api/users/${this.newActeur.actor}`);
        table.splice(0, 1);

        this.$store
          .dispatch("users/updateUser", {
            id: this.newActeur.actor,
            organisationRatachement:
              "/api/referentiel/organisations/" + this.newActeur.Organisation,
          })
          .then(() => {
            // this.$store.dispatch("entity/updateEntity", item).then(() => {
            this.$store
              .dispatch("entity/fetchEntitie", this.$route.params.id)
              .then(() => {
                this.$store
                  .dispatch("entity/fetchActeurs", this.$route.params.id)
                  .then(() => {
                    Swal.fire("L'acteur est bien créé !", "", "success");
                  });
              });
          });
        // } else {
        //   var edit = this.tableItems
        //     .filter((data) => data.id !== item.id)
        //     .map((data) => data["@id"]);
        //   edit.push(`api/users/${this.newActeur.actor}`);
        //   var editmode = {
        //     id: this.$route.params.id,
        //     acteurs: edit,
        //   };
        //   this.$store.dispatch("entity/updateEntity", editmode).then(() => {
        //     Swal.fire("Acteur est mise à jour  !", "", "success");
        //     this.$store
        //       .dispatch("entity/fetchEntitie", this.$route.params.id)
        //       .then(() => {
        //         this.$store
        //           .dispatch("entity/fetchActeurs", this.$route.params.id)
        //           .then(() => {
        //             this.newActeur.Email = "";
        //             this.newActeur.Statut = "";
        //             this.newActeur.Organisation = "";
        //             this.newActeur.Fonction = "";
        //             this.newActeur.Niveau = "";
        //             this.newActeur.type = "";
        //             this.newActeur.actor = null;
        //           });
        //       });
        //   });
        // }
      }
    },
    removeTeamInputs() {
      if (
        this.newActeur.Niveau !== "" &&
        this.newActeur.type !== "" &&
        this.newActeur.Fonction !== "" &&
        this.newActeur.Email !== "" &&
        this.newActeur.Organisation !== "" &&
        this.newActeur.Statut !== "" &&
        this.newActeur.Niveau !== ""
      ) {
        this.newActeur.Email = "";
        this.newActeur.Statut = "";
        this.newActeur.Organisation = "";
        this.newActeur.Fonction = "";
        this.newActeur.Niveau = "";
        this.newActeur.type = "";
      } else {
        var sata = this.tableItems.find((data) => data.Modify === true);
        if (sata == null) {
          this.tableItems.splice(0, 1);
        } else {
          this.$store.dispatch("entity/fetchActeurs", this.$route.params.id);
        }
      }
    },
    responsables(userId) {
      return this.RESPONSABLES.filter((responsable) =>
        userId && responsable.id === userId
          ? true
          : !this.tableItems.find((item) => item.id === responsable.id)
      )
        .map((responsable) => ({
          value: responsable.id,
          text: responsable.firstname + " " + responsable.lastname,
        }))
        .filter((data) =>
          this.tableItems.some((contribute) => contribute.Modify)
            ? true
            : !this.tableItems.find((item) => item.id === data.value)
        );
    },
  },
  computed: {
    rows() {
      return this.tableItems.length;
    },
    ...mapGetters("organisation", ["ORGANISATION", "ORGANISATIONS"]),
    ...mapGetters("users", ["USER", "function", "hierachie", "RESPONSABLES"]),
    ...mapGetters(["isAdmin", "isSuper"]),
    ...mapGetters("entity", ["ACTEUR", "ENTITIE"]),

    fonction() {
      return this.hierachie;
    },
    hierachie() {
      return this.function;
    },
    // responsables() {
    //   return this.RESPONSABLES.filter(
    //     (responsable) =>
    //       !this.tableItems.find((item) => item.id === responsable.id)
    //   )
    //     .map((responsable) => ({
    //       value: responsable.id,
    //       text: responsable.firstname + " " + responsable.lastname,
    //     }))
    //     .filter((data) =>
    //       this.tableItems.some((contribute) => contribute.Modify)
    //         ? true
    //         : !this.tableItems.find((item) => item.id === data.value)
    //     );
    // },
    organisation() {
      return this.ENTITIE.organisations.map((org) => ({
        value: org.id,
        text: org.libelle,
      }));
      // return this.ORGANISATIONS.map((org) => ({
      //   value: org.id,
      //   text: org.libelle,
      // }));
    },
  },
  watch: {
    ACTEUR() {
      // if (this.ACTEUR) {
      //   this.tableItems = this.ACTEUR.acteurs
      //     ? this.ACTEUR.acteurs
      //     : [this.ACTEUR];
      // }
      let acteurs = [];
      if (
        this.ACTEUR &&
        this.ACTEUR.organisations &&
        this.ACTEUR.organisations.length
      ) {
        this.ACTEUR.organisations.map((organisation) =>
          organisation.users.map((user) => {
            acteurs.push({
              ...user,
              organisation: organisation,
            });
          })
        );
      }
      this.tableItems = acteurs;
    },
  },
};
</script>

<style></style>
