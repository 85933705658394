<template>
  <div>
    <div class="d-flex mb-2">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des organisations
      </span>

      <div class="d-flex justify-content-end">
        <customAddButton
          :disabled="
            disabled ||
            (!(isAdmin || isSuper) ? true : false) ||
            (!organisationsList.length ? true : false)
          "
          text="Affecter organisation"
          @click="addNewTeamInputs"
        ></customAddButton>
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <!-- {{this.ORGANISATIONS}} -->
    <b-table
      responsive
      :items="disabled ? [] : tableItems"
      :fields="legalEntity.tableFields"
      :busy="loading"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-empty
      bordered
      striped
    >
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>

      <template v-slot:cell(organisation_attachment)="data">
        <div
          v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
          class="text-center"
        >
          <b-badge
            variant="primary"
            pill
            @click="
              $emit(
                'showOrganisation',
                data.item.sousOrganisations,
                data.item.libelle
              )
            "
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
          >
            {{ data.value }}
          </b-badge>
        </div>
        <div v-else class="text-center">
          <b-badge
            variant="primary"
            pill
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
          >
            {{ organisation ? organisation : 0 }}
          </b-badge>
        </div>
      </template>

      <template v-slot:cell(label)="data">
        <b-link
          v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
          @click="
            $router.push({
              name: 'show-organisation',
              params: {
                slug: data.item.slug ? data.item.slug : '8787',
                id: data.item.id,
              },
            })
          "
          >{{ data.value }}
        </b-link>
        <div v-else>
          <v-select
            appendToBody
            placeholder="Organisation"
            label="text"
            :reduce="({ value }) => value"
            style="
              min-width: max-content !important;
              padding-right: 0 !important;
            "
            :class="{
              'is-invalid form-control p-0':
                $v.libelle.$error && $v.libelle.$dirty,
            }"
            :options="organisationsList"
            v-model="libelle"
            @input="selectEvent"
          >
          </v-select>

          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.libelle.$dirty"
          >
            {{ !$v.libelle.required ? "Champ obligatoire" : "" }}
          </span>
        </div>
      </template>
      <template v-slot:cell(organisation_code)="data">
        <span
          v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
        >
          {{ data.value }}
        </span>
        <b-form-input
          v-else
          type="text"
          disabled
          v-model="code"
          placeholder="Code organisation"
        />
      </template>
      <template v-slot:cell(level)="data">
        <span
          v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
          >{{ data.value }}</span
        >
        <b-form-input
          v-else
          type="number"
          disabled
          v-model="niveau"
          placeholder="Niveau"
        />
      </template>
      <template v-slot:cell(type)="data">
        <span
          v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
        >
          {{ data.value }}
        </span>

        <b-form-select
          v-else
          v-model="type"
          disabled
          :options="typeorg"
          style="min-width: max-content !important"
        ></b-form-select>
      </template>
      <template v-slot:cell(actions)="data">
        <table-actions
          :actions="isAdmin || isSuper ? ['delete'] : []"
          :editMode="data.item.hasOwnProperty('editMode')"
          @cancelItem="removeTeamInputs"
          @deleteItem="deleteItem(data.item.id)"
          @editItem="editItem(data.item, data.item.id)"
          @confirmItem="addorganisation(data.item.id)"
        />
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import customAddButton from "@/components/custom/CustomAddButton";

export default {
  components: { TableActions, customAddButton },
  props: {
    fields: Array,
    items: Array,
    name: String,
    // loading: Boolean,
    disabled: Boolean,
  },
  data: () => ({
    loading: true,
    editedItem: null,
    sortBy: "legal_entity",
    perPage: 10,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    entitie: "",
    libelle: "",
    organisation: null,
    code: "",
    type: "",
    niveau: "",
    tableItems: [],
    legalEntity: {
      tableFields: [
        // { key: "legal_entity", label: "Entité juridique", sortable: true },
        { key: "label", label: "Libellé", sortable: true },
        {
          key: "organisation_code",
          label: "Code organisation",
          sortable: true,
        },
        {
          key: "organisation_attachment",
          label: "Organisation de rattachement",
          sortable: true,
        },
        { key: "level", label: "Niveau", sortable: true },

        { key: "type", label: "Type", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
          sortable: false,
        },
      ],
    },
  }),

  validations: {
    libelle: {
      required,
    },
  },

  methods: {
    addNewTeamInputs() {
      if (this.isAdmin || this.isSuper) {
        var sata = this.tableItems.find((data) => data.editMode === true);
        if (sata == null) {
          this.currentPage = 1;
          this.tableItems.unshift({ editMode: true });
        }
      }
    },
    editItem(data, id) {
      this.$store
        .dispatch("entity/fetchOrganisation", this.$route.params.id)
        .then(() => {
          this.tableItems = this.tableItems.map((contributer) =>
            contributer.id === id
              ? { ...contributer, editMode: true, Modify: true }
              : contributer
          );

          // this.entitie = data.legal_entity;
          window.console.log(data.entite);
          // this.entitie = data.entite["@id"];
          this.ENTITIES.map((entite) => {
            if (
              entite.organisations.find(
                (organisation) => organisation.id === data.id
              )
            ) {
              this.entitie = entite["@id"];
            }
          });
          this.organisation = data.sousOrganisations.length;
          this.libelle = data["@id"];
          this.code = data.organisation_code;
          this.niveau = data.level;
          this.type = data.type;
        });
    },
    addorganisation(id) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var sata = this.tableItems.find((data) => data.Modify === true);

        if (sata == null) {
          var data = null;
          this.tableItems.splice(0, 1);
          if (this.tableItems.length > 0) {
            this.tableItems[0].organisations.push({ "@id": this.libelle });
            data = this.tableItems[0].organisations.map(
              (contrr) => contrr["@id"]
            );
          } else {
            data = [this.libelle];
          }
          this.$store
            .dispatch("entity/updateEntity", {
              id: this.$route.params.id,
              organisations: data,
            })
            .then(() => {
              Swal.fire("L'organisation est bien créée !", "", "success");

              this.$store.dispatch(
                "entity/fetchOrganisation",
                this.$route.params.id
              );
              this.libelle = null;
              this.$v.$reset();
            });
        } else {
          window.console.log(this.tableItems);
          var da = this.tableItems[0].organisations
            .filter((data) => data.id !== id)
            .map((contrr) => contrr["@id"]);
          da.push(this.libelle);
          this.$store
            .dispatch("entity/updateEntity", {
              id: this.$route.params.id,
              organisations: da,
            })
            .then(() => {
              Swal.fire("L'organisation est mise à jour !", "", "success");

              this.$store.dispatch(
                "entity/fetchOrganisation",
                this.$route.params.id
              );
              this.entitie = "";
              this.organisation = "";
              this.code = "";
              this.niveau = "";
              this.type = "";
              this.libelle = "";
              this.$v.$reset();
            });
        }
      }
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          var da = this.tableItems[0].organisations
            .filter((data) => data.id !== id)
            .map((contrr) => contrr["@id"]);
          this.$store
            .dispatch("entity/updateEntity", {
              id: this.$route.params.id,
              organisations: da,
            })
            .then(() => {
              Swal.fire("Supprimé!", "", "success");

              this.$store.dispatch(
                "entity/fetchOrganisation",
                this.$route.params.id
              );
              this.$v.$reset();
            });
        }
      });
    },
    removeTeamInputs() {
      if (this.libelle !== "") {
        this.entitie = "";
        this.organisation = "";
        this.code = "";
        this.niveau = "";
        this.type = "";
        this.libelle = "";
      } else {
        var sata = this.tableItems.find((data) => data.Modify === true);
        if (sata == null) {
          this.tableItems.splice(0, 1);
        } else {
          this.$store.dispatch(
            "entity/fetchOrganisation",
            this.$route.params.id
          );
          this.$v.$reset();
        }
      }
    },
    selectEvent() {
      var res = this.ORGANISATIONS.filter(
        (data) => data["@id"] == this.libelle
      );
      // this.entitie = this.name;
      this.organisation = res[0].sousOrganisations.length;
      this.code = res[0].code;
      this.niveau = res[0].niveau;
      this.type = res[0].type;
    },
  },

  created() {
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("entity/fetchAllEntities");
    this.$store.dispatch("type/fetchOrganisationTypes");
    this.$store
      .dispatch("entity/fetchOrganisation", this.$route.params.id)
      .then(() => {
        this.loading = false;
      });
  },

  computed: {
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("entity", ["ENTITIES", "ORGANISATION"]),
    ...mapGetters("type", ["ORG_TYPES"]),
    ...mapGetters(["isAdmin", "isSuper"]),
    typeorg() {
      return this.ORG_TYPES;
    },
    organisationsList() {
      return this.ORGANISATIONS.filter(
        (organisation) =>
          !(
            this.ORGANISATION &&
            this.ORGANISATION.organisations &&
            this.ORGANISATION.organisations.find(
              (orga) => orga["@id"] === organisation["@id"]
            )
          ) && !organisation.entitie
      ).map((data) => ({
        value: data["@id"],
        text: data.libelle,
      }));
    },
    entitiesList() {
      return this.ENTITIES.map((entite) => ({
        text: entite.libelle,
        value: entite["@id"],
      }));
    },
    rows() {
      return this.tableItems ? this.tableItems.length : 0;
    },
  },
  watch: {
    ORGANISATION() {
      if (this.ORGANISATION)
        this.tableItems = this.ORGANISATION.organisations.map((data) => ({
          ...data,
          legal_entity: this.ORGANISATION.libelle,
          organisations: this.ORGANISATION.organisations,
          label: data.libelle,
          organisation_code: data.code,
          organisation_attachment: data.sousOrganisations
            ? data.sousOrganisations.length
            : 0,
          level: data.niveau,
          type: data.type,
          actions: ["edit", "delete"],
        }));
    },
  },
};
</script>

<style>
@media (min-width: 600px) {
  .align-btn-table-entity-lg {
    position: relative;
    left: -120px;
  }
}
</style>
